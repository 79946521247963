import React from 'react';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import omit from 'lodash/omit';
import 'react-dates-17/initialize';
import { DateRangePicker, DateRangePickerShape, isInclusivelyAfterDay } from 'react-dates-17';

import ThemedStyleSheet from 'react-with-styles/lib/ThemedStyleSheet';
import AphroditeInterface from 'react-with-styles-interface-aphrodite';
import DefaultTheme from 'react-dates-17/lib/theme/DefaultTheme';
import WithStylesContext from 'react-with-styles/lib/WithStylesContext';

import 'react-dates-17/lib/css/_datepicker.css';

// Check https://github.com/airbnb/react-dates/blob/6c2cb61c5e876fbdac72e6efed49c0bd64f06d6b/src/theme/DefaultTheme.js
const MyTheme = {
    reactDates: {
      ...DefaultTheme.reactDates,
      color: {
        ...DefaultTheme.reactDates.color,
        highlighted: {
          backgroundColor: '#82E0AA',
          backgroundColor_active: '#58D68D',
          backgroundColor_hover: '#58D68D',
          color: '#186A3B',
          color_active: '#186A3B',
          color_hover: '#186A3B',
        },
      },
      sizing: {
          ...DefaultTheme.reactDates.sizing,
          inputWidth: 400,
      }
    },
  };
ThemedStyleSheet.registerInterface(AphroditeInterface);
ThemedStyleSheet.registerTheme({
  reactDates: {
    ...DefaultTheme.reactDates,
    color: {
      ...DefaultTheme.reactDates.color,
      highlighted: {
        backgroundColor: '#82E0AA',
        backgroundColor_active: '#58D68D',
        backgroundColor_hover: '#58D68D',
        color: '#186A3B',
        color_active: '#186A3B',
        color_hover: '#186A3B',
      },
    },
    sizing: {
        ...DefaultTheme.reactDates.sizing,
        inputWidth: 400,
    }
  },
});


const calendarLabel = 'Calendar';
const roleDescription = 'datepicker';
const closeDatePicker = 'Close';
const focusStartDate = 'Interact with the calendar and add the check-in date for your trip.';
const clearDate = 'Clear Date';
const clearDates = 'Clear Dates';
const jumpToPrevMonth = 'Move backward to switch to the previous month.';
const jumpToNextMonth = 'Move forward to switch to the next month.';
const keyboardShortcuts = 'Keyboard Shortcuts';
const showKeyboardShortcutsPanel = 'Open the keyboard shortcuts panel.';
const hideKeyboardShortcutsPanel = 'Close the shortcuts panel.';
const openThisPanel = 'Open this panel.';
const enterKey = 'Enter key';
const leftArrowRightArrow = 'Right and left arrow keys';
const upArrowDownArrow = 'up and down arrow keys';
const pageUpPageDown = 'page up and page down keys';
const homeEnd = 'Home and end keys';
const escape = 'Escape key';
const questionMark = 'Question mark';
const selectFocusedDate = 'Select the date in focus.';
const moveFocusByOneDay = 'Move backward (left) and forward (right) by one day.';
const moveFocusByOneWeek = 'Move backward (up) and forward (down) by one week.';
const moveFocusByOneMonth = 'Switch months.';
const moveFocustoStartAndEndOfWeek = 'Go to the first or last day of a week.';
const returnFocusToInput = 'Return to the date input field.';
const keyboardForwardNavigationInstructions = 'Navigate forward to interact with the calendar and select a date. Press the question mark key to get the keyboard shortcuts for changing dates.';
const keyboardBackwardNavigationInstructions = 'Navigate backward to interact with the calendar and select a date. Press the question mark key to get the keyboard shortcuts for changing dates.';

const chooseAvailableStartDate = ({ date }) => `Choose ${date} as your check-in date. It’s available.`;
const chooseAvailableEndDate = ({ date }) => `Choose ${date} as your check-out date. It’s available.`;
const chooseAvailableDate = ({ date }) => date;
const dateIsUnavailable = ({ date }) => `Not available. ${date}`;
const dateIsSelected = ({ date }) => `Selected. ${date}`;
const dateIsSelectedAsStartDate = ({ date }) => `Selected as start date. ${date}`;
const dateIsSelectedAsEndDate = ({ date }) => `Selected as end date. ${date}`;

const START_DATE = 'startDate';
const END_DATE = 'endDate';
const HORIZONTAL_ORIENTATION = 'horizontal';
const ANCHOR_LEFT = 'left';
const NAV_POSITION_TOP = 'navPositionTop';
const OPEN_DOWN = 'down';

const DateRangePickerPhrases = {
    calendarLabel,
    roleDescription,
    closeDatePicker,
    clearDates,
    focusStartDate,
    jumpToPrevMonth,
    jumpToNextMonth,
    keyboardShortcuts,
    showKeyboardShortcutsPanel,
    hideKeyboardShortcutsPanel,
    openThisPanel,
    enterKey,
    leftArrowRightArrow,
    upArrowDownArrow,
    pageUpPageDown,
    homeEnd,
    escape,
    questionMark,
    selectFocusedDate,
    moveFocusByOneDay,
    moveFocusByOneWeek,
    moveFocusByOneMonth,
    moveFocustoStartAndEndOfWeek,
    returnFocusToInput,
    keyboardForwardNavigationInstructions,
    keyboardBackwardNavigationInstructions,
    chooseAvailableStartDate,
    chooseAvailableEndDate,
    dateIsUnavailable,
    dateIsSelected,
    dateIsSelectedAsStartDate,
    dateIsSelectedAsEndDate,
};

const propTypes = {
    // example props for the demo
    autoFocus: PropTypes.bool,
    autoFocusEndDate: PropTypes.bool,
    stateDateWrapper: PropTypes.func,
    initialStartDate: momentPropTypes.momentObj,
    initialEndDate: momentPropTypes.momentObj,

    ...omit(DateRangePickerShape, [
        'startDate',
        'endDate',
        'onDatesChange',
        'focusedInput',
        'onFocusChange',
    ]),
};

const defaultProps = {
    // example props for the demo
    autoFocus: false,
    autoFocusEndDate: false,
    initialStartDate: null,
    initialEndDate: null,

    stateDateWrapper: date => date,
};

class DateRangePickerWrapper extends React.Component {
    constructor(props) {
        super(props);

        let focusedInput = null;
        if (props.autoFocus) {
            focusedInput = START_DATE;
        } else if (props.autoFocusEndDate) {
            focusedInput = END_DATE;
        }

        this.state = {
            focusedInput,
            startDate: props.initialStartDate,
            endDate: props.initialEndDate,
        };

        this.onDatesChange = this.onDatesChange.bind(this);
        this.onFocusChange = this.onFocusChange.bind(this);
    }

    onDatesChange({ startDate, endDate }) {
        const { stateDateWrapper } = this.props;
        this.setState({
            startDate: startDate && stateDateWrapper(startDate),
            endDate: endDate && stateDateWrapper(endDate),
        });
        this.props.onDatesChange(startDate, endDate);
    }

    onFocusChange(focusedInput) {
        this.setState({ focusedInput });
    }

    render() {
        const { focusedInput, startDate, endDate } = this.state;

        // autoFocus, autoFocusEndDate, initialStartDate and initialEndDate are helper props for the
        // example wrapper but are not props on the SingleDatePicker itself and
        // thus, have to be omitted.
        const props = omit(this.props, [
            'autoFocus',
            'autoFocusEndDate',
            'initialStartDate',
            'initialEndDate',
            'stateDateWrapper',
        ]);

        return (
            <WithStylesContext.Provider value={{
                stylesInterface: AphroditeInterface,
                stylesTheme: MyTheme
            }}>
                <DateRangePicker
                    {...props}
                    startDateId={START_DATE}
                    endDateId={END_DATE}
                    onDatesChange={this.onDatesChange}
                    onFocusChange={this.onFocusChange}
                    focusedInput={focusedInput}
                    startDate={startDate}
                    endDate={endDate}
                    regular={true}
                />
            </WithStylesContext.Provider>
        );
    }
}

DateRangePickerWrapper.propTypes = propTypes;
DateRangePickerWrapper.defaultProps = defaultProps;

export default DateRangePickerWrapper;